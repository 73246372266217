import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230207-Rate-Sheet.pdf";
const date = "07.02.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Tuesday 7 February 2023 we will be making the following changes to our range:</p>
    <p>
      <strong>Summary of changes</strong>
    </p>

    <p>
      <strong>UK Residential Existing Customer Switching Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Residential Existing Customer Borrowing More Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Residential First Time Buyer Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Residential Home Mover Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Residential Remortgage Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Buy to Let Existing Customer Switching Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced </li>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60% and 65% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Buy to Let Existing Customer Borrowing More Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60% and 65% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Buy to Let Purchase Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>2 Year Tracker Standard at 75% LTV has reduced</li>
    </ul>
    <p>
      <strong>UK Buy to Let Remortgage Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>
    <p>
      <strong>International Residential Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70% and 75% LTV have reduced</li>
    </ul>
    <p>
      We have reintroduced our Two Year Fixed Rate Fee Saver offering across our UK Buy to Let Existing Customer
      Borrowing More; UK Buy to Let Purchase and UK Buy to Let Remortgage Ranges.
    </p>
    <p>
      We have also introduced our £250 cashback incentive to our Two Year Fixed Fee Saver offering at 95% LTV within our
      UK First Time Buyer and Home Mover Ranges.
    </p>
    <p>There are no changes to any other interest rates at this time.</p>
    <p>An updated mortgage rate sheet will be published on Tuesday 7 February to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
